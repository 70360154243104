@font-face{font-family:'Altocity';font-style:normal;font-weight:100;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-thin.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-thin.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-thin.ttf') format('truetype')}
@font-face{font-family:'Altocity';font-style:normal;font-weight:200;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-light.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-light.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-light.ttf') format('truetype')}
@font-face{font-family:'Altocity';font-style:normal;font-weight:normal;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-regular.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-regular.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-regular.ttf') format('truetype')}
@font-face{font-family:'Altocity';font-style:normal;font-weight:bold;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-medium.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-medium.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-medium.ttf') format('truetype')}
@font-face{font-family:'Altocity-light';font-style:normal;font-weight:normal;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-light.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-light.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-light.ttf') format('truetype')}
@font-face{font-family:'Altocity-regular';font-style:normal;font-weight:bold;font-display:auto;src:url('https://img.altocity.io/static/fonts/Altocity-regular.woff2') format('woff2') , url('https://img.altocity.io/static/fonts/Altocity-regular.woff') format('woff') , url('https://img.altocity.io/static/fonts/Altocity-regular.ttf') format('truetype')}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside,
section {
  display: block;
}

html,
body {
  min-height: 100%;
  width: 100%;
}

body {
  font-family: Altocity, Avenir, Helvetica, sans-serif;
  line-height: 1em;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // margin-bottom: 120px;
}
#hlogo{
  float: left;
  width: 120px;
  height: 80px;
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
}
input,
button,
textarea {
  font-family: Altocity, Avenir, Helvetica, sans-serif;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

body {
  font-family: Altocity, Avenir, Helvetica, sans-serif;
  letter-spacing: normal;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  height: 100%;
}

a {
  font-family: Altocity, Avenir, Helvetica, sans-serif, sans-serif;
  color: #F00;
}

.wrapper {
  min-height: 100vh;
  overflow:visible;
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.container {
  max-width: 1470px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
}

.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background: #000000;
  z-index: 5;
}

.header__body {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__burger {
  display: none;
  position: relative;
  width: 25px;
}

// .header__burger::before, .header__burger::after,
// .header__burger span {
//   content: "";
//   position: absolute;
//   width: 25px;
//   height: 2px;
//   background-color: #fff;
//   left: 0;
//   -webkit-transition: all 0.3s ease 0s;
//   transition: all 0.3s ease 0s;
// }

// .header__burger::after {
//   top: 16px;
// }

// .header__burger::before {
//   top: 0;
// }

// .header__burger span {
//   top: 8px;
// }

// .header__burger.active {
//   z-index: 50;
// }

// .header__burger.active span {
//   -webkit-transform: scale(0);
//           transform: scale(0);
// }

// .header__burger.active::before {
//   -webkit-transform: rotate(45deg);
//           transform: rotate(45deg);
//   top: 8px;
// }

// .header__burger.active::after {
//   -webkit-transform: rotate(-45deg);
//           transform: rotate(-45deg);
//   top: 8px;
// }

#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
  background-color: #616161 !important;
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background-color: #616161 !important;
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  right: 0;
  display: block;
}

.menu__btn {
  display: flex;
  align-items: center;
  top: 20px;
  left: 20px;
  position: relative;
  width: 26px;
  height: 26px;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #fff !important;

  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';

  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0;
  padding-top: 60px;
  list-style: none;
  background-color: #000;
  transition-duration: .25s;
  display: none;
  text-align:center;
}

.menu {
  display: block;
  padding: 18px 24px;
  color: #FFF;
  font-family: Altocity, Avenir, Helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: 200;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.1em;
  width:70%;
}
.menu__item {
  display: block;
  padding: 18px 24px;
  color: #FFF;
  font-family: Altocity, Avenir, Helvetica, sans-serif;
  font-size: 0.7em;
  font-weight: 200;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 0.1em;
}
.menu__item:hover {
  color: #F00;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}
.menu__item.active {
  color: #F00;
}
///////
.header__icon {
  margin-left: 30px;
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {
  .header {
    height: 60px;
  }
  .header__icon {
    display: none;
  }
  .header__menu {
    display: none;
  }
  .header__burger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .header .container {
    max-width: none;
  }
  .header__body {
    padding: 0 10px;
  }
  .header__logo {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    margin-left: -2em;
  }
}
.menu__list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu__list-link {
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.menu__list-link:hover {
  color: #ff0000;
}

.menu__list-link:focus {
  color: #a30000;
}

@media (max-width: 768px) {
  .menu.active {
    display: block;
    position: fixed;
    top: 60px;
    width: 100%;
    left: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .menu.active .menu__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    background: #000;
  }
  .menu.active .menu__list-item {
    margin-bottom: 15px;
  }
  .menu__list-item:last-child {
    display: block;
  }
}
.home {
  margin-top: 80px;
  margin-bottom: 100px;
}

.home__body {
  width:100%;
  margin: 0;
}
#hhome{
  width:100%;
  height: 100vh;
  position:relative;
}

.home-under-construction {
  background-image: url('https://img.altocity.io/static/img/under_construction.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  &:before {
    content: "";
    display: block;
    padding-bottom: 50%;
  }
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 7vw;
    line-height: 1em;
    font-weight: bold;
  }
  h1 {
    position: absolute;
    top: 80%;
    left: 50%;
    color: #fff;
    font-weight: bold;
    font-size: 3vw;
    line-height: 1em;
    textAlign: 'center';
    transform: translate(-50%, 0);
  }
}

.home__title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  margin-bottom: 65px;
}
.gen__text {
  font-size: 0.7em;
  line-height: 1.4em;
  color: #000000;
  margin:10vw;
  margin-top:0;
}
.gen__title {
  font-size: 2em;
  line-height: 2em;
  text-align: center;
  color: #000000;
  margin:10vw;
  margin-bottom:0.5em;
}
.home__text {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.home__text p {
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .home{
    margin-top: 60px;
    margin-bottom:184px;
  }
  .home__body {
    max-width: none;
  }
  #hhome{
    width:100%;
    height: 100vh;
  }
}

.footer {
  position: absolute;
  width: 100%;
  background: #333333;
  bottom: 0;
  left: 0;
}

.footer__body {
  height: 100%;
  display:block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 60px;
}

.footer__copy {
  color: #ddd;
  text-align: left;
  font-size: 0.5em;
  white-space: nowrap;
  float: left;
  clear: left;
}
.footer__made {
  color: #ddd;
  text-align: right;
  font-size: 0.5em;
  white-space: nowrap;
  text-align: right;
  float: right;
  clear: right;
}
.flogo{
  width:1.4em;
  padding-top: 0.2em;
}

.footer__wrapper {
  margin-top: 0.7em;
  align-items: center;
  display: block;
  width: 100%;
}
.footer__footer {
  padding-top: 0.5em;
  clear:both;
  width: 100%;
}

.footer__soc {
  width: 20%;
  float: right;
}
@media (max-width: 768px) {
  .footer__body {
    padding: 0 0px;
  }
  .footer {
    height: 184px;
    padding-top: 30px;
    padding-bottom: 8px;
  }
  .footer__body{
    display:block;
  }
  .footer__wrapper {
    margin-top: 0;
  }
  .footer__copy {
    font-size: 14px;
    line-height: 16px;
  }
  .footer__soc {
    margin-left: 45px;
  }
  .footer-menu__list {
    display: block;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .footer__footer{
    padding-top: 0.9em;
  }
  .footer__copy{
    float:none;
    text-align: center;
  }
  .footer__made{
    float:none;
    text-align: center;
  }
}

.footer-menu {
  width: 50%;
  float: left;
}

.footer-menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-menu__list-link {
  color: #ddd;
  font-size: 0.6em;
  font-weight: 200;
}
.footer-menu__list-link:hover {
  text-decoration: underline;
}

.footer-soc__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-soc__list-item {
  margin-right: 25px;
}

.footer-soc__list-item:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .footer-soc {
    width: 100%;
  }
  .footer-menu{
    width: 100%;
  }
  .footer-soc__list-link img {
    max-width: 26px;
  }
  .footer-soc__list {
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: flex-start;
  }
  .footer-soc__list-item {
    margin: 0.5em;
  }
  .footer-soc__list-item:last-child {
    padding-top: 3px;
  }
}

.gallery {
  width: 100%;
  height: 100%;
  margin-top: 117px;
  padding-bottom: 100px;
  &__body {
    position: relative;
    top:-60px;
    width: 100%;
  }
}

.gallery__search {
  overflow: hidden;
}

@media (max-width: 680px) {
  .gallery {
    margin-top: 90px;
    padding-bottom: 40%;
  }
}

.gallery-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-search__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-search__column input {
  border: 2px solid #000000;
  border-radius: 7px;
  font-size: 1em;
  color: #000000;
  padding: 5px 10px;
  width: 100%;
  font-weight: 200;
}

.gallery-search__column:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.gallery-search__column:last-child {
  margin-left: 40px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 380px;
  flex: 0 1 380px;
}

.gallery-search__column input::-webkit-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-search__column input:-ms-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-search__column input::-ms-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-search__column input::placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-search__toggles {
  text-align: center;
  width: 60px;
  height: 25px;
}

.gallery-search__ios-toggle, .gallery-search__ios-toggle:active {
  position: absolute;
  top: -5000px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
}

.gallery-search__checkbox-label {
  display: block;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  height: 30px;
  border-radius: 18px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border: 2px solid #000000;
}

.gallery-search__checkbox-label::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  line-height: 34px;
  text-indent: 40px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  top: -2px;
  left: -2px;
  right: auto;
  background: white;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.25s linear;
  transition: 0.25s linear;
  border: 2.5px solid #ff0000;
}

.gallery-search__ios-toggle:checked + .gallery-search__checkbox-label {
  -webkit-box-shadow: inset 0px 0px 0px 20px black;
  box-shadow: inset 0px 0px 0px 20px black;
}

.gallery-search__ios-toggle:checked + .gallery-search__checkbox-label::before {
  left: calc(100% - 28px);
  /*box-shadow*/
  -webkit-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(255, 0, 0, 0.3);
  box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(255, 0, 0, 0.3);
}

.gallery-search__item {
  font-size: 1em;
  font-weight: 100;
  margin-right: 0.3em;
  display: inline-block;
  line-height: 1em;
}

.gallery-search__item:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .gallery-search {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .gallery-search__column {
    width: 90%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .gallery-search__column:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .gallery-search__item span {
    display: none;
  }
  .gallery-search__item {
    margin-right: 30px;
    text-transform: capitalize;
  }
}

.gallery-images__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  margin: 1vw;

  .item-image-wrapper {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      transition: transform 0.3s ease-in-out;
    }
  }
  &:hover .item-image-wrapper img {
    transform: scale(1.15);
  }

  p {
    margin: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-items: center;
    align-items: center;
    margin: 1em;

    li {
      width: 11%;

      img {
        transition: transform 0.3s ease-in-out;
        width: 100%;
        height: 100%;
      }

      &.active img {
        animation: rotateActive 0.3s linear;
        transform: rotate(0deg);
      }

      &.inactive img {
        animation: rotateInactive 0.3s linear;
        transform: rotate(45deg);
      }
    }
  }
}


.filter-panel {
  background: #ffffff;
  padding: 1em;
  border: 1px solid #500;
  border-radius: 10px;

  label {
    display: block;
    margin-bottom: 10px;
  }

  select {
    width: 100%;
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
  }
}

@keyframes rotateActive {
  from { transform: rotate(45deg); }
  to { transform: rotate(0deg); }
}

@keyframes rotateInactive {
  from { transform: rotate(0deg); }
  to { transform: rotate(45deg); }
}

.gallery-images__item a{
  font-size: 0.7em;
  line-height: 2em;
  color: #000000;
}

@media (max-width: 768px) {
  .gallery-images__item{
    height: 55vw;
    margin: 1vw;
    width: 40vw;
  }
  .gallery-images__item a{
    font-size: 0.6em;
    line-height: 1em;
  }
}

.wallet-have-images{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
}

.wallet-have-images__item{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1vw;
  width: 30%;
  display: inline-flex;
  text-align: center;
  .wallet-have__name {
    color:black;
    padding: 1em;
  }
  :hover {
    text-decoration: underline;
  }
  img{
    width: 100%;
    height: 100%;
    margin-bottom: 0.5em;
  }

  a{
    font-size: 0.7em;
    line-height: 2em;
    color: #000000;
  }

  @media (max-width: 768px) {
    .wallet-have-images__item a{
      font-size: 0.6em;
      line-height: 1em;
    }
  }
  @media screen and (max-width: 768px) {
    width: 90%; // Full width on mobile
  }
}



.asset {
  width: 100%;
  height: 100%;
  margin-top: 140px;
}

.asset__body {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.asset__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 105px;
}

.asset__title {
  font-size: 1.3em;
  line-height: 1.5em;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
  display: none;
}

.asset__image {
  margin-right: 40px;
}

.asset__image img {
  width: 50vw;
}

.asset__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .asset {
    margin-top: 100px;
  }
  .asset__body {
    max-width: none;
    padding-bottom:120px;
  }
  .asset__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 105px;
  }
  .asset__title {
    display: block;
  }
  .asset-info__title {
    display: none;
  }
  .asset__image img {
    width: 100%;
  }
  .asset__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 0px;
    margin-bottom: 40px;
    width: 100%;
  }
}


.asset-info__title {
  font-size: 1.3em;
  line-height: 1.5em;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.asset-info__text {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 40px;
}

.asset-info__pub {
  margin-bottom: 50px;
}

.asset-info__pub-message {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  background: rgba(255, 0, 0, 0.5);
  border: 1px solid #ff0000;
  border-radius: 20px;
  padding: 15px 60px;
  cursor: pointer;
}

.asset-prop {
  width: 100%;
  height: 100%;
}

.asset-prop__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.asset-prop__item {
  width: 100%;
  cursor:pointer;
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid #F00;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
  padding-top: 2px;
}

.asset-prop__key {
  font-weight: 200;
  font-size: 0.5em;
  line-height: 1.3em;
  color: #000;
  margin-bottom: 4px;
}

.asset-prop__value {
  font-weight: 100;
  font-size: 1em;
  line-height: 1em;
  color: #f00;
  padding-bottom: 0.5em;
}

@media (max-width: 1100px) {
  .asset-prop__item {
    margin-bottom: 25px;
  }
}

@media (max-width: 1006px) {
  .asset-prop__item:last-child {
    margin-bottom: 0px;
  }
}

.asset-sub {
  margin-bottom: 3em;
}

.asset-sub__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.asset-sub__list-link {
  text-decoration: underline;
  text-align: center;
}

.asset-sub__list-link {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.asset-sub__list-span {
  font-size: 0.4em;
  line-height: 1em;
  color: #000000;
}

@media (max-width: 768px) {
  .asset-sub__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .asset-sub__list-item {
    margin-bottom: 0.3em;
  }
  .asset-sub__list-item:last-child {
    margin-bottom: 0;
  }
}

.mint-dc__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom:30vh;
}
.mint-dc__title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  margin-top: 100px;
  margin-bottom: 25px;
}
.mint-dc__image {
  margin-bottom: 1em;
}
.mint-dc__image img {
  max-width: 90vw;
}

.button {
  max-width: 210px;
  margin: 0 auto;
  background: #000000;
  border-radius: 10px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.button__link {
  padding: 15px 70px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-variant: small-caps;
  line-height: 16px;
  color: #ffffff;
}

.mint-c {
  width: 100%;
  height: 100%;
  margin-top: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-bottom: 125px;
}

.mint-c__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  top:-60px;
  width: 100%;
}

.mint-c__title {
  background-image: url('https://img.altocity.io/static/img/soldout.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 4vw;

  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  & span {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    font-size: 10vw;
    font-weight: bold;
    width: 100%;
  }
  .mint-c__status{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display:block;
    text-align: center;
    width: 100%;
    font-size: 4vw;
    color: #F00;
  }
  .mint-b__status{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    display:block;
    text-align: center;
    width: 100%;
  }
  .mint-b__status img{
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-left: 1vw;
  }
  .mint-b__status a{
    cursor: pointer;
    color: #fff;
  }
  .mint-b__status a:hover{
    text-decoration: underline;
  }
  .mint-a__status{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -70%);
    display:block;
    text-align: center;
    width: 100%;
  }
  .mint-a__status img{
    width: 1em;
    height: 1em;
    vertical-align: middle;
    margin-left: 1vw;
  }
  .mint-a__status a{
    cursor: pointer;
    color: #fff;
  }
  .mint-a__status a:hover{
    text-decoration: underline;
  }
}

.mint-c__list {
  max-width: 630px;
  padding: 0 15px;
  width: 100%;
}

.mint-c__item {
  margin-bottom: 30px;
}

.mint-c__item:last-child {
  margin-bottom: 0;
}

.mint-c__item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.mint-c__item-price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.mint-c__item-amount {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #ff0000;
}

.mint-c__subtitle {
  font-size: 0.7em;
  line-height: 1.3em;
  color: #000000;
}
.mint-c__subtitle__sub{
  font-size: 0.7em;
  line-height: 0.9em;
  font-style: italic;
}

.mint-c__terms {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  text-align: center;
}

.mint-c__button {
  margin-top: 70px;
}

.mint-c__link {
  padding: 15px 50px;
}

@media (max-width: 768px) {
  .mint-c {
    margin-top: 100px;
    padding-bottom: 240px;
  }
}

.mint-popup {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}

.mint-popup.active {
  display: block;
}

.mint-popup__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.mint-popup__content {
  max-width: 650px;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  padding: 30px 10px;
}

.mint-popup__exit {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
input[type="range"]::-moz-range-progress {
  background-color: #f00;
}
input[type="range"]::-moz-range-track {
  background-color: #fdd;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #f00;
}
input[type="range"]::-ms-fill-upper {
  background-color: #fdd;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    background-color: #fdd;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #f00;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #f00;
    box-shadow: -80px 0 0 80px #f00;
  }

}
.mint-popup__exit-link {
  position:fixed;
  font-size: 32px;
  color: #000000;
  font-size: 48px;
  line-height: 56px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mint-popup__title {
  font-size: 1.4em;
  line-height: 2em;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.mint-popup__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.mint-popup__image a {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color:#000;
  display:contents;
}
.mint-popup__image a:hover span {
  text-decoration: underline;
}
.mint-popup__image a img {
  max-width: 420px;
  width:90%;
  margin-bottom: 10px;
  float:left;
  clear:left;
}

.mint-popup__image a span {
  font-size: 0.8em;
  line-height: 1.4em;
  text-align: center;
  color: #000000;
  float:left;
  clear:left;
}
.mint-popup__images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.mint-popup__images a img {
  max-width: 45%;
  float:left;
}
.mint-popup__images a {
  color:#000;
}
.mint-popup__images a:hover span{
  text-decoration: underline;
}
.mint-popup__images a span {
  margin: 5%;
  color: #000;
  float: left;
  font-size: 0.7em;
  line-height: 1.4em;
  max-width: 44%;
  text-align: left;
}

.mint-popup__subtitle {
  text-align: center;
}

.mint-popup__subtitle-link {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #000000;
}

@media (max-width: 768px) {
  .mint-popup__title {
    font-size: 1.2em;
    line-height: 1.5em;
  }
  .mint-popup__body {
    padding: 0 15px;
  }
  .mint-popup__content {
    max-width: none;
    width: 100%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    border-radius: 10px;
    padding: 30px 8px;
  }
  .mint-popup__image img {
    max-width: 100%;
  }
}

.wallet-dc {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  margin-bottom: 125px;
}

.wallet-dc__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wallet-dc__title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  margin-bottom: 25px;
}

.wallet-dc__image {
  margin-bottom: 20px;
}

.wallet-dc__image img {
  max-width: 408px;
}

@media (max-width: 500px) {
  .wallet-dc {
    margin-top: 80px;
  }
  .wallet-dc__title {
    font-size: 36px;
    line-height: 42px;
  }
  .wallet-dc__image img {
    max-width: 302px;
  }
  .wallet-dc__image {
    margin-bottom: 55px;
  }
}

.wallet-c {
  width: 100%;
  height: 100%;
  margin-top: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 125px;
}

.wallet-c__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wallet-c__title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  margin-bottom: 7px;
}

.wallet-c__code {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 70px;
}

.wallet-c__list {
  max-width: 630px;
  padding: 0 15px;
  width: 100%;
}

.wallet-c__item {
  margin-bottom: 30px;
}

.wallet-c__item:last-child {
  margin-bottom: 0;
}

.wallet-c__item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.wallet-c__item-price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.wallet-c__item-amount {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #ff0000;
}

.wallet-c__subtitle {
  font-size: 18px;
  font-weight: 100;
  line-height: 21px;
  color: #000000;
}

@media (max-width: 500px) {
  .wallet-c {
    margin-top: 100px;
  }
  .wallet-c__title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 7px;
  }
  .wallet-c__code {
    margin-bottom: 45px;
  }
}

.wallet-have {
  width: 100%;
  height: 100%;
  margin-top: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 125px;
}

@media (max-width: 680px) {
  .wallet-have {
    padding-bottom: 20%;
  }
}

.wallet-have__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position:relative;
  top:-90px;
}

.wallet-have__title {
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  color: #FFF;
  margin-bottom: 0.7em;
}

.wallet-have__list {
  //max-width: 630px;
  padding:1em;
  width: 100%;
}

.panel {
  position: absolute;
  right: 0;
  top: 90px;
  z-index: 2;
  margin-left: auto;
  background: white;
  padding: 1em;
  border-radius:30px;

  .item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wallet-detail {
      display: flex;
      justify-content: space-between;
    }

    .item-price,
    .balance{
      line-height: 1.2em;
      margin-right: 1rem;
    }

    .projecteddue {
      line-height: 0.8em;
      font-size: 0.8em;
      margin-right: 1rem;
    }

    .item-amount {
      line-height: 1.2em;
      color:red;
    }

    .item-amount:hover {
      text-decoration: underline;
    }

    .item-amount-projected {
      font-size: 0.8em;
      line-height: 0.8em;
    }
  }

  @media screen and (max-width: 768px) {
    position: static; // Reset position for mobile devices
    width: 100%; // Full width on mobile
  }
}

.auction-panel {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 2;
  margin-left: auto;
  background: white;
  padding: 1em;
  line-height: 1em;
  border-radius: 30px;

  .panelHeader {
    font-size: 0.5em;
    font-weight: bold;
    color:#333333;
  }

  .value {
    font-size: 1.5em;
    color:red;
  }

  p {
    font-size: 0.5em;
  }

  &:hover {
    .panelHeader,
    .value,
    p,
    h2 {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 900px) {
    position: static;
    width: 100%;
    font-size: 1.3em;
    text-align: left;
    padding-left: 2em;
  }
}

.auction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 117px;
  padding-bottom: 100px;

  &__body {
    position: relative;
    top:-60px;
    width: 100%;
  }

  &__title {
    background-image: url('https://img.altocity.io/static/img/auction.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    &:before {
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-weight: bold;
    }
  }

  h1, h2 {
    text-align: center;
    color: #000000;
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 1em;
  }

  h2 {
    margin: 2em;
    color: #f00;
    line-height: 1.7em;
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin: 3%;
  }

  th {
    padding: 1em;
    font-weight: bold;
    font-size: 0.7em;
    background: #000;
    color: #fff;
    min-width: 15%;
  }

  td {
    padding: .3em;
    font-size: 0.8em;
    border: 1px solid #ccc;
  }

  tr.current:hover {
    background-color: #f5f5f5;
    cursor: pointer;
    text-decoration: underline;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 1.3em;
  }
}

.auction_bid, .swap_wrapper, .transfer-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;

  h2 {
    color: #f00;
    margin-bottom: 0.5em;
    font-size: 1.3em;
    display: inline;
  }

  p {
    color: #fff;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  input {
    width: 200px;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .disclaimer {
    font-size: 0.7em;
    font-weight: bold;
    margin:1em;
  }

  .cancel-btn {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 10px;
    margin:1em;
    &:hover {
      background-color: #cd2627;
      color: white;
    }
  }

  .place-bid-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    margin:1em;
    &:hover {
      background-color: #cd2627;
    }
  }


  .copy-container {
    position: relative;
    display: inline;
    color:#fff;
    font-weight: normal;
    font-size: .8em;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    .copy-btn {
      position: relative;
      top: -2em;
      background-color: #cd2627;
      color: white;
      border: none;
      padding: 2px;
      border-radius: 5px;
      font-size: 0.5em;
      cursor: pointer;

      &:hover {
        background-color: #f00;
        cursor: pointer;
      }

      &.copied {
        background-color: #3ea93e;
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 1.1em;
    h2 {
      font-size: 0.8em;
    }
  }
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 117px;
  padding-bottom: 100px;

  &__body {
    position: relative;
    top:-60px;
    width: 100%;
  }

  &__title {
    background-image: url('https://img.altocity.io/static/img/success.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    &:before {
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    & span {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-weight: bold;
      width: 100%;
      line-height: 1em;
    }

    .success__transfer-panel {
      position: absolute;
      top:60%;
      width: 100%;
      font-size: 1.2em;
      line-height: 1em;
      & img {
        position: absolute;
        left:20vw;
        width: 15vw;
        height: 15vw;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .success__transfer-userinfo {
      position: absolute;
      top:60%;
      width: 50vw;
      left: 35vw;
      font-size: 3.4vw;
      line-height: 1.2em;
      text-align: left;
      margin: 2vw;
      & h2 {
        & div {
          font-size: 1.1em;
          display: inline;
          font-weight: bold;
          color: red;
        }
      }
      & h1 {
        font-weight: bold;
      }
    }

  }

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 1.3em;
  }
}
.cancel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 117px;
  padding-bottom: 100px;

  &__body {
    position: relative;
    top:-60px;
    width: 100%;
  }

  &__title {
    background-image: url('https://img.altocity.io/static/img/cancel.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    &:before {
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-weight: bold;
      width: 100%;
      line-height: 1em;
    }

    & div {
      position: absolute;
      top: 85%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 5vw;
      font-weight: bold;
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 1.3em;
  }
}

.wallet-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 80px;
  padding-bottom: 100px;

  &__body {
    position: relative;
    top:-60px;
    width: 100%;
  }

  &__title {
    background-image: url('https://img.altocity.io/static/img/auction.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    &:before {
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    & span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10vw;
      font-weight: bold;
    }
  }

  h1, h2 {
    text-align: center;
    color: #000000;
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 1em;
  }

  h2 {
    margin: 2em;
    color: #f00;
    line-height: 1.7em;
  }

  .transactions-table {
    border-collapse: collapse;
    width: 90%;
    margin: 3%;
  }

  table {
    border-collapse: collapse;
    width: 90%;
    margin: 3%;
  }

  th {
    padding: 1em;
    font-weight: bold;
    font-size: 0.7em;
    background: #000;
    color: #fff;
    min-width: 15%;
  }

  td {
    padding: .3em;
    font-size: 0.8em;
    border: 1px solid #ccc;
  }

  tr.current:hover {
    background-color: #f5f5f5;
  }

  .button-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .transfer-button {
    background-color: #cd2627;
    color: white;
    border: none;
    padding: 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.9em;
    margin: 1.7em;
    cursor: pointer;
    border-radius: 20px;
  }

  .discord-transfer-button {
    background-color: #7c32af;
    color: white;
    border: none;
    padding: 1.5em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.9em;
    margin: 1.7em;
    cursor: pointer;
    border-radius: 20px;
  }

  .discord-transfer-button:hover, .transfer-button:hover {
    background-color: #f00;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 1.3em;
  }
}

.alto-pfp {
  background: white;
}

.wallet-detail-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1em;

  .AuctionBid {
    background-color: red;
    color: white;
    border: none;
    padding: 1em;
    margin: 1em;
    border-radius: 10px;
    font-weight: bold;

    &:hover {
      background-color: #cd2627;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    width: 100%;
    margin: 1em;
  }

  .api-error {
    font-size: 0.9em;
    margin:1em;
    color:red;
  }

  .swap-btn {
    background-color: #800;
    color: white;
    border: none;
    padding: 1em;
    border-radius: 10px;
    margin: 1em;

    &:hover {
      background-color: #ff2627;
    }
  }
}



.wallet-have__item {
  margin-bottom: 30px;
}

.wallet-have__item:last-child {
  margin-bottom: 0;
}

.wallet-have__item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.wallet-have__item-price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.wallet-have__item-amount {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #ff0000;
}

.gallery-search {
  background-image: url('https://img.altocity.io/static/img/gallery.png');
}
.wallet-have__title {
  background-image: url('https://img.altocity.io/static/img/wallet.png');
}
.wallet-history__title {
  background-image: url('https://img.altocity.io/static/img/transfer.png');
}
.wallet-have__title, .wallet-history__title, .gallery-search {
  background-size: cover;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;

  &:before {
    content: "";
    display: block;
    padding-bottom: 50%;
  }
  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    width: 100%;
  }
  .gallery-search__column {
    width: 100%;
    position:absolute;
    top:70%;
    align-items: center;
    align-content: center;
    margin:0;

    input {
      display: block;
      width: 80vw;
      border: 2px solid #000000;
      border-radius: 10px;
      font-size: 0.7em;
      color: #000000;
      padding: 0.8em;
      font-weight: 200;
      position: relative;
      left: 10vw;
    }
  }
  .gallery-search__column1 {
    width: 100%;
    position:absolute;
    top:40%;
    align-items: center;
    align-content: center;

    input {
      display: block;
      border: 2px solid #000000;
      border-radius: 10px;
      font-size: 0.7em;
      color: #000000;
      padding: 0.8em;
      font-weight: 200;
    }
  }
  .gallery-search__column2 {
    position:absolute;
    top:70%;
    width: 100%;
    align-items: center;
    align-content: center;
  }
}

@media (max-width: 700px) {
  .wallet-have {
    margin-top: 100px;
  }
  .wallet-have__title, .wallet-history__title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 7px;
  }
}

.wallet-sww {
  width: 100%;
  height: 100%;
  margin-top: 120px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-bottom: 125px;
}

.wallet-sww__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 15px;
}

.wallet-sww__title {
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  margin-bottom: 7px;
}

.wallet-sww__code {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-bottom: 115px;
}

.wallet-sww__list {
  max-width: 630px;
  padding: 0 15px;
  width: 100%;
}

.wallet-sww__item {
  margin-bottom: 30px;
}

.wallet-sww__item:last-child {
  margin-bottom: 0;
}

.wallet-sww__item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.wallet-sww__item-price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.wallet-sww__item-amount {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #ff0000;
}

.wallet-sww__subtitle {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

@media (max-width: 500px) {
  .wallet-sww {
    margin-top: 100px;
  }
  .wallet-sww__title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 7px;
  }
  .wallet-sww__code {
    margin-bottom: 45px;
  }
}

.wallet-asset {
  width: 100%;
  height: 100%;
  margin-top: 135px;
}

.wallet-asset__body {
  padding: 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 140px;
}

.wallet-asset__image {
  margin-right: 40px;
}

.wallet-asset__image img {
  width: 50vw;
  margin-bottom:.7em;
}

.wallet-asset__top.resp {
  display: none;
}
.wallet-asset__top.desc {
  margin-bottom: 1.4em;
}

.wallet-asset__title {
  font-size: 1.3em;
  line-height: 1.3em;
  text-align: center;
  color: #000000;
}

.wallet-asset__subtitle {
  text-align: center;
  line-height: .9em;
  margin-bottom: .4em;
}

.wallet-asset__subtitle a {
  font-size: 0.7em;
  line-height: 1.3em;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #000000;
}
.wallet-asset__subtitle a:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}
@media (max-width:950px){
  .wallet-asset__image img {
    width: 45vw;
  }
}
@media (max-width: 768px) {
  .wallet-asset__title {
    font-size: 1em;
    line-height: 1.1em;
    margin-bottom: 1em;
  }
  .wallet-asset__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 .5em;
    margin-bottom: 200px;
  }
  .wallet-asset {
    margin-top: 80px;
  }
  .wallet-asset__top.desc {
    display: none;
  }
  .wallet-asset__top.resp {
    display: block;
  }
  .wallet-asset__image {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .wallet-asset__image img {
    width: 100%;
  }
  .wallet-asset__subtitle a{
    font-size: .5em;
    line-height: 1em;
  }
}

.wallet-asset__details {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 20px;
}

.wallet-asset__total {
  margin-bottom: 10px;
}

.wallet-asset__sub {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 40px;
}


.asset-total {
  padding: 20px;
  border-radius: 10px;
  background: rgba(255,0,0,.1);
}

.asset-total__title-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.asset-total__due {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.asset-total__amount {
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #F00;
}

.asset-content__item .asset-content__table {
  display: none;
}

// .asset-content__item:active .asset-content__table {
//   display: table;
// }
// .asset-content__item:focus .asset-content__table {
//   display: table;
// }
.asset-content__table.show{
  display: table;
}


.asset-content__item .asset-content__dropdown {
  border: 5px solid transparent;
  border-top: 10px solid black;
  margin-bottom: -5px;
  margin-left: 5px;
}

// .asset-content__item:active .asset-content__dropdown {
//   border: 5px solid transparent;
//   border-bottom: 10px solid black;
//   margin-bottom: 5px;
//   margin-left: 5px;
// }

.asset-content__item .asset-content__dropdown.up {
  border: 5px solid transparent;
  border-bottom: 10px solid black;
  margin-bottom: 5px;
  margin-left: 5px;
}

.asset-content__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.asset-content__title {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.asset-content__table {
  margin-bottom: 10px;
}

.asset-table {
  width: 100%;
  margin: 1em;
  border-collapse: collapse;
  text-align: center;
  border-spacing: 0;
  margin-bottom: 10px;
}

.asset-table thead {
  background: #500;
  padding: 2px 0;
}

.asset-table thead tr {
  border: none;
}

.asset-table thead tr td {
  border: none;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #ffffff;
  padding: 1em;
}

.asset-table tbody {
  padding: 2px 0;
  background: #fee;
}

.asset-table tbody tr td {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding: 1em;
}

table,
table > * {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
}

@media (max-width: 500px) {
  .asset-table {
    max-width: none;
    margin: 0;
    margin-bottom: 10px;
  }
  .wallet-asset__total {
    margin-bottom: 30px;
  }
}

.gallery-dop {
  width: 100%;
  height: 100%;
  margin-top: 117px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.gallery-dop__search {
  max-width: 1330px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 500px) {
  .gallery-dop {
    margin-top: 90px;
  }
}

.gallery-dop-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-dop-search__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-dop-search__column input {
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  padding: 5px 10px;
  width: 100%;
}

.gallery-dop-search__column:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  background: #000;
  padding: 20px 70px;
}

.gallery-dop-search__column input::-webkit-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-dop-search__column input:-ms-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-dop-search__column input::-ms-input-placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-dop-search__column input::placeholder {
  font-size: 24px;
  line-height: 28px;
  color: #777777;
}

.gallery-dop-search__toggles {
  width: 60px;
  margin: 40px auto;
  text-align: center;
}

.gallery-dop-search__ios-toggle, .gallery-dop-search__ios-toggle:active {
  position: absolute;
  top: -5000px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
}

.gallery-dop-search__checkbox-label {
  display: block;
  position: relative;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  height: 30px;
  border-radius: 18px;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  border: 2px solid #000000;
}

.gallery-dop-search__checkbox-label::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  line-height: 34px;
  text-indent: 40px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  top: -2px;
  left: -2px;
  right: auto;
  background: white;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  border: 2.5px solid #ff0000;
}

.gallery-dop-search__ios-toggle:checked + .gallery-dop-search__checkbox-label {
  -webkit-box-shadow: inset 0px 0px 0px 20px black;
  box-shadow: inset 0px 0px 0px 20px black;
}

.gallery-dop-search__ios-toggle:checked + .gallery-dop-search__checkbox-label::before {
  left: calc(100% - 28px);
  -webkit-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
}

.gallery-dop-search__item {
  font-size: 24px;
  line-height: 28px;
  margin-right: 7px;
}

.gallery-dop-search__item:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .gallery-dop-search {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .gallery-dop-search__column {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .gallery-dop-search__column:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .gallery-dop-search__item span {
    display: none;
  }
  .gallery-dop-search__item {
    margin-right: 30px;
    text-transform: capitalize;
  }
  .gallery-dop-search__column:first-child {
    padding: 10px 25px;
  }
  .gallery-dop-search__toggles {
    margin: 25px auto;
  }
}

.gallery-search__ios-toggle:checked + .gallery-search__checkbox-label {
  -webkit-box-shadow: inset 0px 0px 0px 100px black;
  box-shadow: inset 0px 0px 0px 100px black;
}

.gallery-dop-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-start;
  max-width: 1410px;
  margin: 0 auto;
  padding: 0 15px;
}

.gallery-dop-images__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 40px;
}

.gallery-dop-images__item img {
  margin-bottom: 10px;
  width: 25vw;
}

.gallery-dop-images__item a {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  width: 25vw;
  text-align: center;
}
.infinite-scroll-component__outerdiv{
  margin-bottom: 100px;
}

@media (max-width: 1100px) {
  .gallery-dop-images__item {
    margin: 30px;
  }
  .gallery-dop-images__item img {
    width: 25vw;
  }

}

@media (max-width: 850px) {
  .gallery-dop-images__item {
    margin: 20px;
  }
}

@media (max-width: 768px) {
  .gallery-dop-images__item {
    margin: 10px 12px 10px 12px;
  }
  .gallery-dop-images__item a {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 680px) {
  .gallery-dop-images__item img {
    width: 40vw;
  }
}

@media (max-width: 500px) {
  .gallery-dop-images {
    margin: 0 -24px;
  }
  .gallery-dop-images__item img {
    margin-bottom: 5px;
  }
}

.gallery-dop-popup-sww {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}

.gallery-dop-popup-sww.active {
  display: block;
}

.gallery-dop-popup-sww__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.gallery-dop-popup-sww__content {
  max-width: 650px;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  padding: 30px 10px;
}

.gallery-dop-popup-sww__exit {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 48px;
  line-height: 56px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.gallery-dop-popup-sww__title {
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
  margin-bottom: 45px;
}

.gallery-dop-popup-sww__text {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

@media (max-width: 768px) {
  .gallery-dop-popup-sww__body {
    padding: 0 25px;
  }
}

.gallery-dop-popup-tt {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;
}

.gallery-dop-popup-tt.active {
  display: block;
}

.gallery-dop-popup-tt__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
}

.gallery-dop-popup-tt__content {
  max-width: 650px;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  padding: 30px 10px;
}

.gallery-dop-popup-tt__exit {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 48px;
  line-height: 56px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.gallery-dop-popup-tt__title {
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;
}

.gallery-dop-popup-tt__area {
  width: 100%;
  padding: 0 8px;
  margin-bottom: 30px;
}

.gallery-dop-popup-tt__area textarea {
  width: 100%;
  resize: none;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  height: 200px;
  border: 1px solid #000000;
  background: #ffffff;
  padding: 10px;
}

.gallery-dop-popup-tt__area textarea::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__area textarea:-ms-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__area textarea::-ms-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__area textarea::placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__text {
  width: 100%;
  padding: 0 8px;
  margin-bottom: 40px;
}

.gallery-dop-popup-tt__text input {
  font-size: 18px;
  line-height: 25px;
  color: #000;
  background: #ffffff;
  border: 1px solid #000000;
  padding: 3px 10px;
  width: 100%;
}

.gallery-dop-popup-tt__text input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__text input:-ms-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__text input::-ms-input-placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__text input::placeholder {
  font-size: 18px;
  line-height: 25px;
  color: #999999;
}

.gallery-dop-popup-tt__pub {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.gallery-dop-popup-tt__pub-message {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  background: rgba(255, 0, 0, 0.5);
  border: 1px solid #ff0000;
  border-radius: 20px;
  padding: 15px 60px;
}

@media (max-width: 550px) {
  .gallery-dop-popup-tt__area textarea {
    font-size: 14px;
    line-height: 25px;
    height: 300px;
  }
  .gallery-dop-popup-tt__area textarea::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__area textarea:-ms-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__area textarea::-ms-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__area textarea::placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__text input {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__text input::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__text input:-ms-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__text input::-ms-input-placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__text input::placeholder {
    font-size: 14px;
    line-height: 25px;
  }
  .gallery-dop-popup-tt__pub-message {
    padding: 15px 43px;
  }
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #000;
  margin-top: 70px;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
